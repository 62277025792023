//汽运客户 路由
export default [
	
	//经销商网点相关
	{
		path:'transit_business_outlets/outlets_list',
		component(){
			return import('@/pages/transit_business_outlets/outlets/list.vue')
		}
	},


	//经销商网点用户相关
	{
		path:'transit_business_outlets/outlets_user_list',
		component(){
			return import('@/pages/transit_business_outlets/user/list.vue')
		}
	},


	//货主对网点授权相关
	{
		path:'transit_business_outlets/auth_grant_list',
		component(){
			return import('@/pages/transit_business_outlets/grant/auth_grant_list.vue')
		}
	},
	
	//后台给调度分配运单
	{
		path:'transit_business_outlets/order_list',
		component(){
			return import('@/pages/transit_business_outlets/order/list.vue')
		}
	}
]