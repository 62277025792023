export default {
	
	//选择文件
	choose(options={}){
		
		//文件大小(默认 100M)
		let size=options.size?options.size:'100000000'//bit
		
		//文件类型(默认:所有文件)
		let accept=options.accept?options.accept:'*'
		
		//input对象实例
		let input=document.createElement('input')
		input.type='file'
		input.accept=accept
		input.addEventListener('change',()=>{
			if(input.files[0].size>size){
				this.__parent.other.msg({
					type:'warning',
					str:'文件过大'
				});
			}
			
			let res={
				file:input.files[0]
			};
			if(window.createObjectURL!=undefined){ // basic
				res.src = window.createObjectURL(input.files[0]);
			}else if(window.URL!=undefined){ // mozilla(firefox)
				res.src=window.URL.createObjectURL(input.files[0]) ;
			}else if(window.webkitURL!=undefined){ // webkit or chrome
				res.src=window.webkitURL.createObjectURL(input.files[0]) ;
			}
			
			//回调
			options.success(res)
		})
		input.click()
	},
	
}