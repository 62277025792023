/* 各种校验 */
const check = {
	is_num:function(num){
		var re=/^[0-9]+.?[0-9]*$/;
		return re.test(num);
	},
	obj_length:function(obj){
		if(!this.is_obj(obj)){
			return 0;
		}
		let len=0;
		for(let i in obj){
			len++;
		}
		return len;
	},
	is_obj:function(obj){
		if(typeof(obj)!='object'){
			return false;
		}else{
			if(Array.isArray(obj)){
				return false;
			}else{
				return true;
			}
		}
	},
	is_arr:function(arr){
		return Array.isArray(arr)
	},
	is_str:function(str){return (typeof(str)=='string')},
	is_fun:function(fun){return (typeof fun === "function")},
	is_bl:function(num){//判断是否是营业执照号码
		var reg=/^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/;
		return reg.test(num)
	},
	is_bank_card:function(num){//判断是否是银行卡号
		var reg=/^([1-9]{1})(\d{14}|\d{15}|\d{16}|\d{17}|\d{18}|\d{19}|\d{22})$/;
		return reg.test(num)
	},
	id_card_num:function(num){//判断是否是身份证号码
		var reg=/^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
		return reg.test(num)
	},
	is_post:function(num){//判断是否是邮政编码
		var reg=/^[1-9][0-9]{5}$/;
		return reg.test(num)
	},
	is_tel:function(tel){//判断是否是电话号码
		var reg1=/^1[3456789]{1}\d{9}$/;
		var reg2=/^([0-9]{3,4}-)?[0-9]{7,8}$/;
		var reg3=/^\d{3,4}-\d{3,4}-\d{3,4}$/;
		return reg1.test(tel)||reg2.test(tel)||reg3.test(tel)
	},
	is_email:function(str){//判断是否是邮箱地址
		var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/; 
		return reg.test(str); 
	},
	is_plate_num:function(str){//判断是否是车牌号
		var reg = /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[0-9a-zA-Z]{5}$/u; 
		var reg2 = /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[DF]{1}[0-9A-Z]{5}$/u;
		var reg3 = /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[A-Z]{1}[0-9A-Z]{5}$/u;//八位牌
		var reg4 = /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[A-Z]{1}[0-9A-Z]{5}[DF]{1}$/u;
		if(reg.test(str)){
			return reg.test(str);
		}else if(reg2.test(str)){
			return reg2.test(str);
		}else if(reg3.test(str)){
			return reg3.test(str);
		}else if(reg4.test(str)){
			return reg4.test(str);
		}
	},
	is_vin_code:function(str){//判断是否是车架号
		var reg = /^[A-HJ-NPR-Z\d]{17}$/; 
		return reg.test(str); 
	},
	is_lat:function(str){//判断是否是纬度
		var reg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/; 
		return reg.test(str); 
	},
	is_lng:function(str){//判断是否是经度
		var reg = /^[\-\+]?(0(\.\d{1,10})?|([1-9](\d)?)(\.\d{1,10})?|1[0-7]\d{1}(\.\d{1,10})?|180\.0{1,10})$/; 
		return reg.test(str); 
	},
	is_login_pwd:function(str){//登录密码格式校验
		let zmReg = /[A-Za-z]/; // 大小写字母
		let numReg = /[0-9]/; // 数字
		let zfReg = /[^A-Za-z0-9\s]/; // 特殊字符不包含空格
		let empty = /\s/g;
		let chinese = /[\u4e00-\u9fa5]/g;
		let complex = 0;
		if (chinese.test(str)) {
			return false;
		}
		if (empty.test(str)) {
			return false;
		}
		if (zmReg.test(str)) {
			++complex;
		}
		if (numReg.test(str)) {
			++complex;
		}
		if (zfReg.test(str)) {
			++complex;
		}
		if (complex < 2 || str.length < 6 || str.length > 16) {
			
			// 密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数
			return false;
		} else {
			return true;
		}
	},
}

export default check