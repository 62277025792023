//app用户 的页面路由
export default [{
		path:'app_user/user_list',
		component(){
			return import('@/pages/app_user/user_info/list.vue')
		}
	},{
		path:'app_user/company_list',
		component(){
			return import('@/pages/app_user/company/list.vue')
		}
	},{
		path:'app_user/agent_list',
		component(){
			return import('@/pages/app_user/sale_agent/list.vue')
		}
    },
	{
		path:'app_user/app_user_bankcard',
		component(){
			return import('@/pages/app_user/bankcard/list.vue')
		}
	},{
		path:'app_user/user_driver_truck_creat',
		component(){
			return import('@/pages/app_user/other_act/user_driver_truck_creat.vue')
		}
	},{
		path:'app_user/user_shipper_creat',
		component(){
			return import('@/pages/app_user/other_act/user_shipper_creat.vue')
		}
	},

	
	
	//司机相关
	{
		path:'app_user/driver_list',
		component(){
			return import('@/pages/app_user/driver/driver_list.vue')
		}
	},{
		path:'app_user/driver_add',
		component(){
			return import('@/pages/app_user/driver/driver_add.vue')
		}
	},{
		path:'app_user/driver_class',
		component(){
			return import('@/pages/app_user/driver/class/list.vue')
		}
	},


	//货车相关
	{
		path:'app_user/truck_list',
		component(){
			return import('@/pages/app_user/truck/truck_list/list.vue')
		}
	},{
		path:'app_user/truck_type',
		component(){
			return import('@/pages/app_user/truck/truck_type/list.vue')
		}
	},{
		path:'app_user/truck_add',
		component(){
			return import('@/pages/app_user/truck/truck_list/add.vue')
		}
	},
	//车队长列表
	{
		path:'app_user/fleet_list',
		component(){
			return import('@/pages/app_user/fleet/list.vue')
		}
	},
	//车队长列表
	{
		path:'app_user/truck_owner_list',
		component(){
			return import('@/pages/app_user/truck_owner/list.vue')
		}
	},
	//销售只读
	{
		path:'sale_read/tord_list',
		component(){
			return import('@/pages/sale_read/tord_list.vue')
		}
	},{
		path:'sale_read/company_list',
		component(){
			return import('@/pages/sale_read/company_list.vue')
		}
	},{
		path:'sale_read/invoice_list',
		component(){
			return import('@/pages/sale_read/invoice_list.vue')
		}
	},

	//数据统计
	{
		path:'statistics/data_statistics',
		component(){
			return import('@/pages/data_statistics/data_statistics_list.vue')
		}
	},
	//运单评价
	{
		path:'tord_evaluate/list',
		component(){
			return import('@/pages/app_user/tord_evaluate/list.vue')
		}
	},
]