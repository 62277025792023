/* 无法归类的工具 */
export default {
	copy(str){//复制 待实现
		
	},
	msg(obj){//消息
		this.__parent.__app.$message({
			type: obj.type,
			message: obj.str,
		})
	},
	confirm(obj){//消息

		let title=obj.title?obj.title:'温馨提醒';

		let content=obj.content?obj.content:'请确认操作';

		let type=obj.type?obj.type:'warnning';

		this.__parent.__app.$confirm(content,title,{
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: type,
			callback: action => {
				if (action === 'confirm') {
					if(this.__parent.check.is_fun(obj.confirm)){
						obj.confirm();
					}
				}else {
					if(this.__parent.check.is_fun(obj.cancel)){
						obj.cancel();
					}
				}
			}
		})
	},
	totime(timestamp,not_need_time){//时间戳转时间字符串
		if(timestamp>99999999999){
			return '无';
		}
		var d = new Date(timestamp * 1000);    //根据时间戳生成的时间对象
		var month=d.getMonth()+1;
		month=month<10?"0"+month:month;
		
		var data=d.getDate();
		data=data<10?"0"+data:data;
		
		if(not_need_time){
			return (d.getFullYear())+"-"+month+"-"+data;
		}
		
		var hour=d.getHours();
		hour=hour<10?"0"+hour:hour;
		
		var minutes=d.getMinutes();
		minutes=minutes<10?"0"+minutes:minutes;
		
		var seconds=d.getSeconds();
		seconds=seconds<10?"0"+seconds:seconds;
		
		return (d.getFullYear())+"-"+month+"-"+data+" "+hour+":"+minutes+":"+seconds;
	},
	todate(timestamp,not_need_time){//时间戳转时间字符串(仅日期)
		if(timestamp>99999999999){
			return '无';
		}
		var d = new Date(timestamp * 1000);    //根据时间戳生成的时间对象
		var month=d.getMonth()+1;
		month=month<10?"0"+month:month;
		
		var data=d.getDate();
		data=data<10?"0"+data:data;
		
		if(not_need_time){
			return (d.getFullYear())+"-"+month+"-"+data;
		}
		
		return (d.getFullYear())+"-"+month+"-"+data
	},
	url:{
		encode(str){
			return escape(str);
		},
		decode(str){
			return unescape(str);
		},
	},
	obj_length:function(obj){//对象长度
		if(!this.is_obj(obj)){
			return 0;
		}
		let len=0;
		for(let i in obj){
			len++;
		}
		return len;
	},
}