//实时单 路由
export default [

	
	//线路相关
	{
		path:'transit_real_time/business_line_list',
		component(){
			return import('@/pages/transit_real_time/business_line/list.vue')
		}
	},

	
	//货源部分
	{
		path:'transit_real_time/cargo_creat_admin',
		component(){
			return import('@/pages/transit_real_time/cargo/creat.vue')
		}
	},{
		path:'transit_real_time/cargo_list_admin',
		component(){
			return import('@/pages/transit_real_time/cargo/list.vue')
		}
	},{
		path:'transit_real_time/cargo_edit_admin',
		component(){
			return import('@/pages/transit_real_time/cargo/edit.vue')
		}
	},


	//运单相关
	{
		path:'transit_real_time/tord_add',
		component(){
			return import('@/pages/transit_real_time/tord/act/add.vue')
		}
	},{
		path:'transit_real_time/tord_deal',
		component(){
			return import('@/pages/transit_real_time/tord/deal/list.vue')
		}
	},{
		path:'transit_real_time/tord_act_list',
		component(){
			return import('@/pages/transit_real_time/tord/act/list.vue')
		}
	},{
		path:'transit_real_time/tord_end_list',
		component(){
			return import('@/pages/transit_real_time/tord/end/list.vue')
		}
	},
	{
		path:'transit_real_time/tord_unusual_list',
		component(){
			return import('@/pages/transit_real_time/tord/act/unusual.vue')
		}
	},
	{
		path:'transit_real_time/tord_look_list',
		component(){
			return import('@/pages/transit_real_time/tord/act/look.vue')
		}
	},
	///运费批量修改
	{
		path:'transit_real_time/tord_batch_edit',
		component(){
			return import('@/pages/transit_real_time/tord/batch_edit.vue')
		}
	},
	//运单批量修改
	{
		path:'transit_real_time/freight_batch_edit',
		component(){
			return import('@/pages/transit_real_time/tord/batch_freight_edit.vue')
		}
	},
	//运单审核
	{
		path:'transit_real_time/tord_examine',
		component(){
			return import('@/pages/transit_real_time/tord/act/tord_examine.vue')
		}
	},
	//轨迹搜索
	{
		path:'transit_real_time/ser_trajectory',
		component(){
			return import('@/pages/transit_real_time/tord/ser_trajectory.vue')
		}
	},
	//财务相关
	{
		path:'transit_real_time/tord_pay_list',
		component(){
			return import('@/pages/transit_real_time/finance/tord_pay/list.vue')
		}
	},{
		path:'transit_real_time/tord_invoice_list',
		component(){
			return import('@/pages/transit_real_time/finance/tord_invoice_list.vue')
		}
	},
]