export default{
	routes:[{
			path:'*',
			component(){
				return import('@/pages/public/menu.vue')
			}
		},{
			name:'login',
			path:'/login',
			component(){
				return import('@/pages/public/login.vue')
			}
		},{
			name:'index',
			path:'/pages',
			component(){
				return import('@/pages/public/menu.vue')
			},
			children:[
				
				//admin部分
				...require('./extend/admin.js').default,

				//app用户部分
				...require('./extend/app_user.js').default,
				
				//财务部分
				...require('./extend/finance.js').default,

				//汽运客户部分
				...require('./extend/transit_business_outlets.js').default,
				
				//汽运后录单部分
				...require('./extend/transit_ord_after_time.js').default,
				
				//汽运实时运单部分
				...require('./extend/transit_ord_real_time.js').default,
				
				//陆运系统上报相关
				// ...require('./extend/transit_logink.js').default,

				//省监管平台相关
				...require('./extend/supervise').default,
			]
		},
	],
};