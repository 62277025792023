//财务相关
export default [
	
	//提现相关
	{
		path:'finance/tord_real_time_outcash_list',
		component(){
			return import('@/pages/finance/out_cash/tord_outcash_list.vue')
		}
	},

	
	//充值相关
	{
		path:'finance/recharge',
		component(){
			return import('@/pages/finance/recharge/recharge.vue')
		}
	},{
		path:'finance/recharge_list_admin',
		component(){
			return import('@/pages/finance/recharge/recharge_list_admin.vue')
		}
	},
	//自动出款页面
	{
		path:'finance/tord_pay_automatic',
		component(){
			return import('@/pages/transit_real_time/finance/tord_pay_automatic.vue')
		}
	},
		//自动出款页面2
	{
		path:'finance/pay_automatic',
		component(){
			return import('@/pages/transit_real_time/finance/tord_pay/pay_automatic.vue')
		}
	},
	//自动查询页面
	{
		path:'finance/query_automatic',
		component(){
			return import('@/pages/transit_real_time/finance/tord_pay/query_automatic.vue')
		}
	},
	//垫资审核列表
	{
		path:'finance/yufu_list',
		component(){
			return import('@/pages/transit_real_time/finance/yufu_list.vue')
		}
	},
	//回款列表
	{
		path:'finance/pay_return_list',
		component(){
			return import('@/pages/transit_real_time/finance/pay_return_list.vue')
		}
	},
	//徽商充值流水列表
	{
		path:'finance/recharge_list',
		component(){
			return import('@/pages/finance/recharge/recharge_list.vue')
		}
	},
	//徽商充值流水记录
		{
			path:'finance/recharge_all',
			component(){
				return import('@/pages/finance/recharge/recharge_all.vue')
			}
		},
	//平台银行卡管理
	{
		path:'finance/platform_bankcard_list',
		component(){
			return import('@/pages/finance/platform_bankcard/list.vue')
		}
	},

]