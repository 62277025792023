import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {

		//初始化
		init_status:1,//初始化状态(1:未初始化,2:初始化中,3:已初始化成功,4:初始化失败)
		agent_para:{},//代理信息
		bucket:{},//七牛bucket
		logink_aim_list:{},//上报目标列表

		//用户相关
		is_login:2,//是否已登录(1:已登录,2:未登录)
		user_info:{},//用户信息
	},
	mutations: {
		end_of_init(state,data){//初始化之后
			if(data.init_status){
				state.init_status=data.init_status
			}
			if(data.agent_para){
				state.agent_para=data.agent_para
			}
			if(data.bucket){
				state.bucket=data.bucket
			}
			if(data.logink_aim_list){
				state.logink_aim_list=data.logink_aim_list
			}
		},
		end_of_login(state,data){//登陆成功之后
			if(data.is_login){
				state.is_login=data.is_login
			}
			if(data.user_info){
				state.user_info=data.user_info
			}
		},
		end_of_logout(state){//登出之后
			state.is_login=2
			state.user_info={}
		},
		save_user_info(state,data){//保存用户数据
			state.user_info=data
		},
	},
	actions: {
		
	}
})

export default store
